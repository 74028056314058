import React from "react";

import DefaultLayout from "../../../layouts/default";

const Lehrlinge = () => (
  <DefaultLayout title="Lehrlinge">
    <div className="orange portrait">
      <div className="navContentSpacerHelperSingleNavBar"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li className="is-active">
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>
        <nav className="subNav2 no-shadow">
          <div className="subNav2Product">
            <div className="productText">
              <span className="line">Die Wigasoft&nbsp;</span>
              <span className="line">als Lehrbetrieb</span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li>
              <a href="/portrait/">Übersicht</a>
            </li>
            <li>
              <a className="is-active" href="/portrait/lehrlinge/">
                Lernende
              </a>
            </li>
            <li>
              <a href="/portrait/partner/">Partner</a>
            </li>
            <li>
              <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
            </li>
            <li>
              <a href="/portrait/kontakt/">Kontakt</a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <section>
          <div className="headerImg moreHeight">
            <img
              className="js-img-size-from-parent"
              width="100%"
              src="https://wigasoft-prod.imgix.net/hommeset.jpg?q=50&w=1200&h=400"
            />
          </div>
          <div>
            <img
              src="/assets/images/gestaltungselemente/jumpstart.svg"
              className="jumpstartImg"
            />
          </div>
        </section>

        <div className="wrapper smallStaticPadding-bottom has-padding-top">
          <section className="cContainer is-background-color-grey">
            <h3 className="smallMedTitle">1 Vakanz ab Sommer 2026</h3>
            <p className="verySpecialTitle">Informatiker/in EFZ</p>
            <h4 className="smallStaticPadding-bottom">
              Schwerpunkt Applikationsentwicklung
            </h4>
            <div className="row">
              <div className="column one-half">
                <p className="verySpecialSubTitle smallStaticPadding-bottom">
                  Unsere Anforderungen
                </p>
                <ul className="cContainerUL">
                  <li>Konzentrationsfähigkeit und Geduld</li>
                  <li>Auffassungs- und Kombinationsgabe</li>
                  <li>Logisches und Abstraktes Denkvermögen</li>
                  <li>Gutes Vorstellungsvermögen</li>
                  <li>Exakte Arbeitsweise</li>
                  <li>Permanente Lernbereitschaft</li>
                  <li>Freude an der Arbeit mit Computern</li>
                </ul>
              </div>
              <div className="column one-half">
                <p className="verySpecialSubTitle smallStaticPadding-bottom">
                  Dein Aufgabenbereich
                </p>
                <ul className="cContainerUL">
                  <li>Planen</li>
                  <li>Strukturiertes und objektorientiertes Programmieren</li>
                  <li>Dokumentieren</li>
                  <li>Qualitätssicherung</li>
                  <li>Mitarbeit im Team</li>
                  <li>Vereinzelt IT-Aufgaben</li>
                </ul>
              </div>
            </div>
            <br />
				<p className="verySpecialSubTitle is-color-orange">Informationsabende für Lehrstellen als Informatiker/in
				</p>
				<p className="fliesstext2 smallStaticPadding-bottom">4. und 12. März 2025</p>

				<a className="is-link-black"
					href="https://jobs.abacus.ch/einstiegsmoeglichkeiten/infoabende"
					target="_blank">Details</a>
          </section>
        </div>

        <div className="wrapper has-padding-top has-padding-bottom">
          <section className="cContainer is-background-color-grey">
            <h3 className="smallMedTitle">Keine Vakanzen</h3>
            <p className="verySpecialTitle">Kauffrau/-mann EFZ</p>
            <h4 className="smallStaticPadding-bottom">
              Dienstleistung und Administration
            </h4>
            <div className="row">
              <div className="column one-half">
                <p className="verySpecialSubTitle smallStaticPadding-bottom">
                  Unsere Anforderungen
                </p>
                <ul className="cContainerUL">
                  <li>schriftliche und mündliche Ausdrucksgewandtheit</li>
                  <li>Teamfähigkeit und Kontaktfreude</li>
                  <li>rasche Auffassungsgabe</li>
                  <li>Zuverlässigkeit und Selbständigkeit</li>
                  <li>Verantwortungsbewusstsein</li>
                  <li>Exakte Arbeitsweise</li>
                  <li>Permanente Lernbereitschaft</li>
                </ul>
              </div>
              <div className="column one-half">
                <p className="verySpecialSubTitle smallStaticPadding-bottom">
                  Dein Aufgabenbereich
                </p>
                <ul className="cContainerUL">
                  <li>Informationsverarbeitung per E-Mail und Telefon</li>
                  <li>Kundenempfang</li>
                  <li>Buchhaltung</li>
                  <li>Bestellwesen</li>
                  <li>allg. Korrespondenz</li>
                  <li>Mithilfe Eventorganisation</li>
                  <li>ablegen / ordnen</li>
                </ul>
              </div>
            </div>
          </section>
        </div>

        <section>
          <div className="wrapper has-padding-bottom">
            <div className="bewerbungFlex has-padding-bottom">
              <div className="is-visible-tablet">
                <img
                  className="jumpstartJumpingText"
                  src="/assets/images/gestaltungselemente/jumpstart-2.svg"
                />
                <img
                  className="jumpstartJumpingPersonImg"
                  src="https://wigasoft-prod.imgix.net/iStock-871625976.png?auto=format&w=440"
                />
                &nbsp;
              </div>
              <div className="has-padding-bottom">
                <h3>Bewerbungsverfahren</h3>
                <p className="largeNumber">1.</p>
                <p className="headline5">Voraussetzungen</p>
                <p>
                  Du besuchst die Sekundarschule und verfügst über gute
                  schulische Leistungen in den mathematischen und
                  naturwissenschaftlichen Fächern.
                </p>
                <p className="largeNumber">2.</p>
                <p className="headline5">
                  Bewerbung (bevorzugt per&nbsp;
                  <a
                    className="is-link-black"
                    href="mailto:lehrstelle@wigasoft.ch"
                  >
                    Mail
                  </a>
                  )
                </p>
                <p>
                  Dann sende uns Dein Motivationsschreiben, Lebenslauf,
                  Zeugnisse und, wenn vorhanden Multicheck, basis-check oder
                  Stellwerk an&nbsp;
                  <a
                    className="is-link-black"
                    href="mailto:lehrstelle@wigasoft.ch"
                  >
                    lehrstelle@wigasoft.ch
                  </a>
                  .
                </p>
                <p className="largeNumber">3.</p>
                <p className="headline5">Schnuppertage und Termine</p>
                <p>
                  Wir kontaktieren Dich, um einen passenen Termin zu
                  vereinbaren.
                </p>
              </div>
              <div className="is-visible-mobile">
                <img
                  className="jumpstartJumpingPersonImg"
                  src="https://wigasoft-prod.imgix.net/iStock-871625976-mobile.png"
                />
                &nbsp;
              </div>
            </div>
          </div>
        </section>

        <section className="cContainer is-background-color-orange is-color-white">
          <div className="wrapper">
            <div className="row">
              <div className="column two-third has-padding-right">
                <h2>Wir machen Profis</h2>
                <p>
                  Die Berufsbildung ist ein Eckpfeiler unseres Unternehmens: Sie
                  ermöglicht Jugendlichen den Einstieg in die Arbeitswelt und
                  sorgt dafür, dass wir dank qualifizierten Fachkräften
                  erfolgreich bleiben.
                </p>
              </div>
              <div className="column one-third text-centered">
                <img src="/assets/images/berufsbildungplus.svg" width="100%" />
              </div>
            </div>
          </div>
        </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/portrait/">Über uns</a>
                </li>
                <li>
                  <a href="/portrait/lehrlinge/">Lernende</a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default Lehrlinge;
